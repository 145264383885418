import React, { useEffect, useContext } from 'react'
import { Box, Text, Heading, } from "theme-ui"
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import HighGrounds from '../components/highgrounds'
import FarmGallery from '../components/farmgallery'
import { handleBenefits } from '../components/helpers'
import Info from '../components/info'
import Subscription from '../components/subscription'
import LinkBox from '../components/linkbox'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import GalleryComp from "../components/galleryComp"
import LangContext from "../components/Context/LangContext"
import { handleLinkLanguage } from "../components/helpers"

const FarmPage = ({ data }) => {
  const {
    setPage, currentPage, setLang, currentLang
  } = useContext(LangContext)

  useEffect(() => {
    setPage("farm")
  }, [])

  useEffect(() => {
    gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText);
    gsap.from('.kruna', { opacity: 0, ease: "expo" })


    gsap.utils.toArray(".splittext").forEach((text, index) => {
      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: text,
          start: 'top bottom',
          end: 'bottom top',
          toggleActions: "play none none none",
          // markers: true,
        }
      });
      new SplitText(text, {
        type: "lines",
        linesClass: `lineChild${index}`
      });
      new SplitText(text, {
        type: "lines",
        linesClass: "o-hidden"
      });

      tl.from(`.lineChild${index}`, { opacity: 0, y: 50, ease: "expo", stagger: 0.05 });
    })
    gsap.utils.toArray(".textimage-img-reverse").forEach((img, index) => {
      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: img,
          toggleActions: "restart pause resume reverse",
        }
      })

      tl.from(img, { rotation: -10, ease: "sine", });
    })

    gsap.utils.toArray(".textimage-img").forEach((img, index) => {
      var tl = gsap.timeline({
        scrollTrigger: {
          trigger: img,

          toggleActions: "restart pause resume reverse",
        }
      })

      tl.from(img, { rotation: 10, ease: "sine", });
    })

    ScrollTrigger.create({
      trigger: '#izohipse',
      // start: 'bottom top',
      // end: 'bottom top',
      animation: gsap.fromTo("#izohipse path", { drawSVG: "50% 50%", }, { duration: 2, drawSVG: "100%", ease: "power1.inOut" }),
      // scrub: 0,
    })

    ScrollTrigger.create({
      trigger: '.galleryTitle',
      start: 'top bottom',
      end: 'bottom top',
      animation: gsap.fromTo('.galleryTitle', { opacity: 0, y: 100, }, { opacity: 1, y: 0, }),
      scrub: false,
      toggleActions: "play none none reverse"
      // markers: true
    })
    ScrollTrigger.create({
      trigger: '.galleryTitle',
      start: 'top bottom',
      end: 'bottom top',
      animation: gsap.from('.slide-image', { x: '200', stagger: +0.1, ease: 'expo', duration: 1.5, }),
      scrub: false,
      toggleActions: "play none none reset"
      // markers: true
    })
    ScrollTrigger.create({
      trigger: '.galleryTitle',
      start: 'top bottom',
      end: 'bottom top',
      animation: gsap.from('.galleryLogo svg', { rotate: 400 }),
      scrub: 1,
      toggleActions: "play none none reverse"
      // markers: true
    })
    ScrollTrigger.create({
      trigger: '.galleryLogo',
      start: 'top bottom',
      end: 'bottom top',
      animation: gsap.to('.galleryLogo svg', { rotate: 400 }),
      scrub: 3,
      toggleActions: "play none none reverse"
      // markers: true
    })
  },
    [])

  const {
    titleRs,
    image,
    image2,
    descriptionRs,
    section1Benefits = [],
    section2Benefits = [],
    linksComponent = [],
    highGroundsTitleRs,
    highGroundsDescriptionRs,
    subsTitleRs,
    subsDescriptionRs,
    galleryTitleRs,
  } = data.allDatoCmsFarm.nodes[0]

  const benefits1Remap = section1Benefits.map(x => {
    return {
      title: x.titleRs,
      description: x.descriptionRs,
      image: x.image
    }
  })

  const benefits2Remap = section2Benefits.map(x => {
    return {
      title: x.titleRs,
      description: x.descriptionRs,
      image: x.image
    }
  })

  const linksComponentRemap = linksComponent.map(x => {
    return {
      title: x.titleRs,
      image: x.image,
      link: handleLinkLanguage(x.link, currentLang),
    }
  })



  return (
    <Box className='hero'
      sx={{
      }}>


      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column', 'row'],
          position: 'relative',
          height: '100vh',
          width: '100%',
          // pt: 70,
          // px: 4,/

        }}>

        {/* slika farme */}
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
            opacity: 0.5,
            '& .gatsby-image-wrapper': {
              height: '100%',
              width: '100%',
            }
          }}>
          <Img fluid={image2.fluid}></Img>

        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            alignItems: ['center', 'center', 'inherit'],
            justifyContent: ['center', 'center', 'flex-end'],
            width: ['100%', '100%', '80%'],
            bottom: [0, 0, 20],
            zIndex: 1,
            pl: [0, 0, 5],
            pb: [0, 0, 5],
            left: 0,
            height: ['100%', '100%', 'inherit'],
          }}>
          {/* kruna */}
          <Box className='kruna'
            sx={{
              display: 'flex',
              width: [130, 130, 100],
              height: [130, 130, 100],
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              bg: 'pink',
              pb: 2,
              mb: 4,
            }}>
            <svg sx={{ width: ['80%', '65%'], height: ['80%', '100%'], '& path': { stroke: 'white', strokeWidth: '3px' } }} viewBox="0 0 76 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.75342 39.5336C1.75342 39.5336 11.7441 20.8093 38.148 20.8093C64.5518 20.8093 74.5425 39.5336 74.5425 39.5336" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
              <path d="M28.8711 21.5296L36.0073 12.1674C37.4345 10.7271 39.5754 10.7271 41.0026 12.1674L47.4252 21.5296" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
              <path d="M38.1479 10.0069C40.5126 10.0069 42.4296 8.07232 42.4296 5.68591C42.4296 3.29949 40.5126 1.36492 38.1479 1.36492C35.7832 1.36492 33.8662 3.29949 33.8662 5.68591C33.8662 8.07232 35.7832 10.0069 38.1479 10.0069Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
              <path d="M54.5615 20.0891C56.9262 20.0891 58.8432 18.1546 58.8432 15.7681C58.8432 13.3817 56.9262 11.4472 54.5615 11.4472C52.1968 11.4472 50.2798 13.3817 50.2798 15.7681C50.2798 18.1546 52.1968 20.0891 54.5615 20.0891Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
              <path d="M21.7348 20.0891C24.0996 20.0891 26.0165 18.1546 26.0165 15.7681C26.0165 13.3817 24.0996 11.4472 21.7348 11.4472C19.3701 11.4472 17.4531 13.3817 17.4531 15.7681C17.4531 18.1546 19.3701 20.0891 21.7348 20.0891Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
              <path d="M64.5517 25.8505C66.1282 25.8505 67.4062 24.5608 67.4062 22.9698C67.4062 21.3789 66.1282 20.0892 64.5517 20.0892C62.9753 20.0892 61.6973 21.3789 61.6973 22.9698C61.6973 24.5608 62.9753 25.8505 64.5517 25.8505Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
              <path d="M11.7441 25.8505C13.3206 25.8505 14.5986 24.5608 14.5986 22.9698C14.5986 21.3789 13.3206 20.0892 11.7441 20.0892C10.1676 20.0892 8.88965 21.3789 8.88965 22.9698C8.88965 24.5608 10.1676 25.8505 11.7441 25.8505Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
              <path d="M74.5425 39.5335C74.5425 39.5335 64.5518 25.8504 38.148 25.8504C11.7441 25.8504 1.75342 39.5335 1.75342 39.5335" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
            </svg>
          </Box>
          <Box
            sx={{
              width: ['100%', '100%', 'inherit'],
              textAlign: ['center', 'center', 'inherit'],
            }}>
            <Heading className='splittext'
              sx={{
                px: [0, 4, 0],
                fontSize: ['12vw', 8, 130],
                lineHeight: 1,
                color: 'white',
                mb: [0, 3, 3],

              }}>

              {titleRs}
            </Heading>
            <Text className='splittext'
              sx={{
                pl: 10,
                pb: 3,
                fontSize: [5, 6, 5],
                color: 'white',
              }}>
              {descriptionRs}
            </Text>
          </Box>

        </Box>

      </Box>

      <HighGrounds
        highGroundsTitle={highGroundsTitleRs}
        highGroundsDescription={highGroundsDescriptionRs}
      ></HighGrounds>

      <Box sx={{
        pt: 5,
        mb: 5,
      }}>
        {/* <GalleryComp
          title={galleryTitleRs}
          galleryArray={data.allDatoCmsFarm.nodes[0].farmGallery}
        /> */}
        {/* <FarmGallery data={data}></FarmGallery> */}
      </Box>
      {handleBenefits(benefits1Remap)}

      {handleBenefits(benefits2Remap)}
      {/* <Info data={data}></Info> */}
      <Subscription
        subsTitle={subsTitleRs}
        subsDescription={subsDescriptionRs}
      ></Subscription>
      <LinkBox linksComponent={linksComponentRemap}></LinkBox>

    </Box>
  )
}
export default FarmPage

export const query = graphql`
query FarmpageRs {
    allDatoCmsFarm {
      nodes {
        titleRs
        descriptionRs
        image {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        image2 {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        highGroundsTitleRs
        highGroundsDescriptionRs

        section1Benefits {
          image {
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }
          descriptionRs
          titleRs
        }
        section2Benefits {
            image {
              fluid {
                ...GatsbyDatoCmsFluid
              }
            }
            descriptionRs
            titleRs
        }
        infoImage {
            fluid {
              ...GatsbyDatoCmsFluid
          }
        }
        infoImage2 {
            fluid {
              ...GatsbyDatoCmsFluid
            }
          }  
        subsTitleRs
        subsDescriptionRs

        galleryTitleRs
        farmGallery {
          fluid { 
            ...GatsbyDatoCmsFluid
          }
        }


        linksComponent {
            titleRs
            link
            image {
                fluid{
                    ...GatsbyDatoCmsFluid
                }
            }
        }
      }
    }
  }`